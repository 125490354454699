import Footer from "./Component/Footer";
import Home from "./Component/Home";
import Navber from "./Component/Navbar";


function App() {
  return (
    <>
      <Navber/>
      <Home/>
      <Footer/>
    </>
  );
}

export default App;
