import React from 'react'

export default function Footer() {
  return (
    <>
        <div className="footer">
            <p>All Copy<span>&copy;</span>Right Reserved  by 51Game! <span></span>Designed by Nirvi!</p>
        </div>
    </>
  )
}
