import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';

export default function Home() {

    useEffect(() => {
        // Set a timeout to open the modal after 5 seconds
        const timer = setTimeout(() => {
            // Get the modal element
            const myModal = new window.bootstrap.Modal(document.getElementById('myModal'));
            myModal.show();
        }, 3000);

        // Clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []);


    return (
        <>

            {/****************Start Slider Section******************/}
            <section>
                <div className="slide">
                    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="5000">
                                <img src="assets/images/slider-bg-3.png" class="d-block w-100 img-fluid" alt="..." style={{ height: "" }} />
                            </div>
                            <div class="carousel-item" data-bs-interval="5000">
                                <img src="assets/images/slider-bg-1.jpg" class="d-block w-100 img-fluid" alt="..." style={{ height: "" }} />
                            </div>
                            <div class="carousel-item" data-bs-interval="5000">
                                <img src="assets/images/slider-bg-4.png" class="d-block w-100 img-fluid" alt="..." style={{ height: "" }} />
                            </div>
                            <div class="carousel-item" data-bs-interval="5000">
                                <img src="assets/images/slider-bg-2.jpg" class="d-block w-100 img-fluid" alt="..." style={{ height: "" }} />
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>
            {/****************End Slider Section******************/}
            <div className="regi_icon mt-5">
                <a href="https://51game.app/#/register?invitationCode=66172133311"><img src="assets/images/reg.png" alt="no image" /></a>
            </div>
            {/****************Start game1 Content Section******************/}
            <section>
                <div className="container-fluid mt-3 mb-3">
                    <div className="game_hding">
                        <div className="heading">
                            <p><img src="assets/images/loutari.png" alt="no image" /> Loteria</p>
                        </div>
                        <div className="heading_icon">
                            <p><span><i class="bi bi-chevron-left"></i></span></p>
                            <p><span><i class="bi bi-chevron-right"></i></span></p>
                        </div>
                    </div>
                    <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4">
                        <div className="col  mb-3">
                            <div class="card ">
                                <img src="assets/images/1.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/2.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/3.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/4.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/****************End game1 Content Section******************/}

            {/****************Start Slider2 Section******************/}
            <section>
                <div className="slide_heading2">
                    <p><img src="assets/images/gamelogo.png" alt="no image" /> Our Game Partners</p>
                </div>
                <div className="slide">
                    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="5000">
                                <img src="assets/images/slide2.jpg" class="d-block w-100 img-fluid" alt="..." style={{ height: "" }} />
                            </div>
                            <div class="carousel-item" data-bs-interval="5000">
                                <img src="assets/images/slide1.jpg" class="d-block w-100 img-fluid" alt="..." style={{ height: "" }} />
                            </div>
                            <div class="carousel-item" data-bs-interval="5000">
                                <img src="assets/images/slide3.jpg" class="d-block w-100 img-fluid" alt="..." style={{ height: "" }} />
                            </div>
                            <div class="carousel-item" data-bs-interval="5000">
                                <img src="assets/images/slide4.jpg" class="d-block w-100 img-fluid" alt="..." style={{ height: "" }} />
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>
            {/****************End Slider2 Section******************/}

            {/****************Start game2 Content Section******************/}
            <section>
                <div className="container-fluid mt-3 mb-3">
                    <div className="game_hding">
                        <div className="heading1">
                            <p><img src="assets/images/hot.png" alt="no image" /> Hot Game</p>
                        </div>
                        <div className="heading_icon">
                            <p><span><i class="bi bi-chevron-left"></i></span></p>
                            <p><span><i class="bi bi-chevron-right"></i></span></p>
                        </div>
                    </div>
                    <div className="row row-cols-3 row-cols-sm-3 row-cols-md-4">
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/5.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/6.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/7.jpg" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/8.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/9.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/10.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/11.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div class="card ">
                                <img src="assets/images/12.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                        <div className="col mb-3 show_hide">
                            <div class="card ">
                                <img src="assets/images/13.png" class="card-img-top" alt="no image here" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/****************End game2 Content Section******************/}

            {/****************Start winners partner Section******************/}
            <section className='mb-3 mx-3'>
                <div className="winner_heading2">
                    <p><img src="assets/images/ccham.png" alt="no image" /> Our winners Partners</p>
                </div>
                <div className="slide">
                    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="2000">
                                <div className="winner">
                                    <div className="slide_img">
                                        <img src="assets/images/w4.jpg" class="d-block W-100  img-fluid" alt="..." style={{ width: "400PX" }} />
                                    </div>
                                    <div className="winner_say">
                                        <div className="winner_img">
                                            <img src="assets/images/person_5.jpg" alt="no image" />
                                        </div>
                                        <div className="winner_conetet">
                                            <div className="winner_heading">
                                                <h3>Digital Earn Money</h3>
                                            </div>
                                            <div className="winner_body">
                                                <p>" I am very  Happy, i say that about this game I've conquered the game with skill and style. Your victory shines bright, Celebrate this moment of triumph—I've earned it! Keep that winning spirit alive and let it fuel our future endeavors. Well done!  "</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="2000">
                                <div className="winner">
                                    <div className="slide_img">
                                        <img src="assets/images/w3.jpg" class="d-block  img-fluid" alt="..." />
                                    </div>
                                    <div className="winner_say">
                                        <div className="winner_img">
                                            <img src="assets/images/person_4.jpg" alt="no image" />
                                        </div>
                                        <div className="winner_conetet">
                                            <div className="winner_heading">
                                                <h3>Digital Earn Money</h3>
                                            </div>
                                            <div className="winner_body">
                                                <p aria-setsize="200">" I am very  Happy, i say that about this game I've conquered the game with skill and style. Your victory shines bright, Celebrate this moment of triumph—I've earned it!  "</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="2000">
                                <div className="winner">
                                    <div className="slide_img">
                                        <img src="assets/images/w2.jpg" class="d-block  img-fluid" alt="..." />
                                    </div>
                                    <div className="winner_say">
                                        <div className="winner_img">
                                            <img src="assets/images/person_3.jpg" alt="no image" />
                                        </div>
                                        <div className="winner_conetet">
                                            <div className="winner_heading">
                                                <h3>Digital Earn Money</h3>
                                            </div>
                                            <div className="winner_body">
                                                <p aria-setsize="200">" I am very  Happy, i say that about this game I've conquered the game with skill and style. Your victory shines bright, Celebrate this moment of triumph—I've earned it! Keep that winning spirit alive and let it fuel our future endeavors. Well done!  "</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="2000">
                                <div className="winner">
                                    <div className="slide_img">
                                        <img src="assets/images/w1.jpg" class="d-block  img-fluid" alt="..." />
                                    </div>
                                    <div className="winner_say">
                                        <div className="winner_img">
                                            <img src="assets/images/person.jpg" alt="no image" />
                                        </div>
                                        <div className="winner_conetet">
                                            <div className="winner_heading">
                                                <h3>Digital Earn Money</h3>
                                            </div>
                                            <div className="winner_body mt-3">
                                                <p aria-setsize="200">" I am very  Happy, i say that about this game I've conquered the game with skill and style. Your victory shines bright, Celebrate this moment of triumph—I've earned it!  "</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>
            </section>
            {/****************End winners partner Section******************/}

            {/****************Start Auto Open Model Section******************/}

            <div>
                <div class="modal" id="myModal" tabindex="-1">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div className="model_content">
                                <h5 class="modal-title">51Game</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal_body">
                                <h4>First Deposit Bonus : Up To <span>&#8377;</span>8000</h4>

                            </div>

                            <div className="btn_reg">
                                <a href="https://51game.app/#/register?invitationCode=66172133311">Ragistion Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/****************End Auto Open Model Section******************/}
            ``
            <div className="btn_reg_bottom">
                <a href="https://51game.app/#/register?invitationCode=66172133311"><button class="btn btn-primary w-100" type="button">Ragistion Now</button> </a>
            </div>
        </>
    )
}
