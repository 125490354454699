import React from 'react'

export default function Navber() {
    return (
        <>
            <div className="nav">
                <div className="nav_item">
                    <div className="logo">
                        <p><img src="
                        assets/images/icon51.png" alt="" /></p>
                    </div>
                    
                    <div className="btn_login d-flex">
                        <p><a href="https://youtube.com/@gulabkumarselfmotivation?si=jqLo39gp6yZ2M_B0"><img src="assets/images/youtube_icon.png" alt="no image" /></a></p>
                        <p className='teligram'><a href="https://t.me/gulabkumar143"><img src="assets/images/telegram_icon.png" alt="no image" /></a></p>
                    </div>
                </div>
            </div>
        </>
    )
}
